<script setup>
import { ref, onMounted, onUnmounted, toRefs } from "vue";

const props = defineProps({
  allshowmessage: Array,
});

const { allshowmessage } = toRefs(props);

//時間表示用
const formatTimestamp = (timestamp) => {
  // Timestampオブジェクトをミリ秒に変換
  const milliseconds =
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
  const date = new Date(milliseconds);

  // フォーマットを適用
  const hh = String(date.getHours()).padStart(2, "0");
  const mm = String(date.getMinutes()).padStart(2, "0");
  const ss = String(date.getSeconds()).padStart(2, "0");

  return `${hh}:${mm}:${ss}`;
};
const messageContainer = ref(null);
const messageList = ref(null);

const scrollToBottom = () => {
  messageContainer.value.scrollTop = messageContainer.value.scrollHeight;
};

const observeMessages = () => {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === "childList") {
        scrollToBottom();
      }
    });
  });

  const config = { childList: true };
  observer.observe(messageList.value, config);

  return observer;
};

let observer;

onMounted(() => {
  observer = observeMessages();
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
  }
});
</script>

<template>
  <h2>全てのメッセージ</h2>
  <div ref="messageContainer" class="message-container">
    <ul ref="messageList">
      <template v-for="item in allshowmessage" :key="item.name">
        <!-- class指定 ランキングによってclassを出し分け 配信者の場合も出し分け-->
        <li
          :class="{
            rank_1: item.ranking == 1,
            rank_2: item.ranking == 2,
            rank_3: item.ranking == 3,
            vendor: item.commenttype == 'commentvendor',
            card_1: item.cardtype == 1,
            card_2: item.cardtype == 2,
            card_3: item.cardtype == 3,
          }"
        >
          <!-- ユーザーのアイコン -->
          <div class="image">
            <div class="icon"><img :src="item.iconURL" /></div>
            <div v-if="item.ranking == 1" class="rank">
              <img src="img/rank1.png" />
            </div>
            <div v-else-if="item.ranking == 2" class="rank">
              <img src="img/rank2.png" />
            </div>
            <div v-else-if="item.ranking == 3" class="rank">
              <img src="img/rank3.png" />
            </div>
          </div>
          <div class="content">
            <!-- commenttypeがgoods,treca意外は表示させない trecaの場合カードの種類によってアイコンを出し分け -->
            <div v-if="item.commenttype == 'goods'" class="goods">
              <img src="/img/comment_gacha.png" />
            </div>
            <div v-else-if="item.commenttype == 'treca'" class="card">
              <img src="/img/comment_card.png" />
            </div>
            <!-- ユーザーの名前 -->
            <div class="name">{{ item.name }}</div>
            <!-- カードの種類によって★の数を決める -->
            <div v-if="item.cardtype == 1" class="cardtype">
              <img class="star" src="/img/comment_star.png" />
              <img class="star" src="/img/comment_star.png" />
              <img class="star" src="/img/comment_star.png" />
            </div>
            <div v-else-if="item.cardtype == 2" class="cardtype">
              <img class="star" src="/img/comment_star.png" />
              <img class="star" src="/img/comment_star.png" />
            </div>
            <div v-else-if="item.cardtype == 3" class="cardtype">
              <img class="star" src="/img/comment_star.png" />
            </div>
            <!-- コメント -->
            <div class="comment">{{ item.message }}</div>
            <!-- 時間 -->
            <div class="time">{{ formatTimestamp(item.timestamp) }}</div>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>
