<template>
  <v-btn rounded class="white--text" color="red darken-4">
    <spam class="room"
      >配信を終了する（別途配信ソフトの配信も停止してください）</spam
    >
  </v-btn>
</template>

<script></script>

<style>
.room {
  color: white;
}
</style>
