<script setup>
import { toRefs, ref, onMounted, onUnmounted } from "vue";
const props = defineProps({
  showmessage: Array,
});

const { showmessage } = toRefs(props);
const messageContainer = ref(null);
const messageList = ref(null);

const scrollToBottom = () => {
  messageContainer.value.scrollTop = messageContainer.value.scrollHeight;
};

const observeMessages = () => {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === "childList") {
        scrollToBottom();
      }
    });
  });

  const config = { childList: true };
  observer.observe(messageList.value, config);

  return observer;
};

let observer;

onMounted(() => {
  observer = observeMessages();
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
  }
});
</script>

<template>
  <!-- <div ref="messageContainer" class="message-container">-->
  <ul ref="messageList">
    <template v-for="item in showmessage" :key="item.name">
      <!-- class指定 ランキングによってclassを出し分け 配信者の場合も出し分け-->
      <li
        :class="{
          rank_1: item.ranking == 1,
          rank_2: item.ranking == 2,
          rank_3: item.ranking == 3,
          vendor: item.commenttype == 'commentvendor',
          card_1: item.cardtype == 1,
          card_2: item.cardtype == 2,
          card_3: item.cardtype == 3,
        }"
      >
        <!-- ユーザーのアイコン -->
        <div class="image">
          <div class="icon"><img :src="item.iconURL" /></div>
          <div v-if="item.ranking == 1" class="rank">
            <img src="img/rank1.png" />
          </div>
          <div v-else-if="item.ranking == 2" class="rank">
            <img src="img/rank2.png" />
          </div>
          <div v-else-if="item.ranking == 3" class="rank">
            <img src="img/rank3.png" />
          </div>
        </div>
        <div class="content">
          <!-- commenttypeがgoods,treca意外は表示させない trecaの場合カードの種類によってアイコンを出し分け -->
          <div v-if="item.commenttype == 'goods'" class="goods">
            <img src="/img/comment_gacha.png" />
          </div>
          <div v-else-if="item.commenttype == 'treca'" class="card">
            <img src="/img/comment_card.png" />
          </div>
          <!-- ユーザーの名前 -->
          <div class="name">{{ item.name }}</div>
          <!-- カードの種類によって★の数を決める -->
          <div v-if="item.cardtype == 1" class="cardtype">
            <img class="star" src="/img/comment_star.png" />
            <img class="star" src="/img/comment_star.png" />
            <img class="star" src="/img/comment_star.png" />
          </div>
          <div v-else-if="item.cardtype == 2" class="cardtype">
            <img class="star" src="/img/comment_star.png" />
            <img class="star" src="/img/comment_star.png" />
          </div>
          <div v-else-if="item.cardtype == 3" class="cardtype">
            <img class="star" src="/img/comment_star.png" />
          </div>
          <!-- コメント -->
          <div class="comment">{{ item.message }}</div>
          <div
            v-if="item.quantity !== null && item.quantity < 10"
            class="quantity"
          >
            <span class="quantityuntsmall">✖️</span
            ><span class="quantityuntil9">{{ item.quantity }}</span>
            <span class="quantityuntsmall">コ</span>
          </div>
          <div
            v-else-if="item.quantity >= 10 && item.quantity < 30"
            class="quantity"
          >
            <span class="quantityuntsmall">✖️</span>
            <spam class="quantityuntil29">{{ item.quantity }}</spam
            ><span class="quantityuntsmall">コ</span>
          </div>
          <div v-else-if="item.quantity >= 30" class="quantity">
            <span class="quantityuntsmall">✖️</span>
            <span class="quantityover30">{{ item.quantity }}</span>
            <span class="quantityuntsmall">コ</span>
          </div>
        </div>
      </li>
    </template>
  </ul>
  <!-- </div>-->
</template>

<style scoped>
ul {
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
ul li {
  display: flex;
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 5px;
}
ul li.vendor {
  background: rgba(28, 221, 255, 0.9);
}
ul li.rank_1 {
  border: 3px solid rgba(255, 179, 0, 0.9);
}
ul li.rank_2 {
  border: 3px solid rgba(100, 100, 100, 0.9);
}
ul li.rank_3 {
  border: 3px solid rgba(175, 88, 0, 0.9);
}
ul li.card_1,
ul li.card_2,
ul li.card_3 {
  background: rgba(255, 87, 87, 0.9);
}
ul li .image {
  position: relative;
  margin-right: 10px;
}
ul li .icon {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  overflow: hidden;
}
ul li .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ul li .image .rank {
  position: absolute;
  display: inline-block;
  top: 17px;
  right: -5px;
  width: 15px;
  height: 17px;
  color: #888;
}
ul li .image .rank img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ul li .content {
  flex: 1;
  padding: 0.4em 0;
  text-align: left;
}
ul li .content .name {
  display: inline;
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #888;
}
ul li.card_1 .content .name,
ul li.card_2 .content .name,
ul li.card_3 .content .name,
ul li.vendor .content .name {
  color: rgba(255, 255, 255, 0.7);
}
ul li.card_1 .content .comment,
ul li.card_2 .content .comment,
ul li.card_3 .content .comment,
ul li.vendor .content .comment {
  color: #fff;
}
ul li .content .cardtype {
  display: inline-block;
  border-radius: 20px;
  background-color: #fff;
  padding: 0 8px 2px 8px;
  margin-right: 5px;
}
ul li .content .cardtype img.star {
  width: 14px;
  height: 15px;
  margin-right: 0;
}
ul li .content .comment {
  display: inline;
  font-size: 14px;
  line-height: 1.2em;
}
ul li .content .quantity {
  display: inline;
  font-size: 14px;
  line-height: 1.2em;
  margin-left: 5px;
}
ul li .content .quantityuntil9 {
  display: inline-block; /* inline-blockで要素を独立させる */
  font-size: 14px;
  line-height: 1.2em;
  text-align: right;
  margin-left: 5px;
  font-weight: bold;
  color: #ff0000;
}
ul li .content .quantityuntil29 {
  display: inline-block; /* inline-blockで要素を独立させる */
  font-size: 14px;
  line-height: 1.2em;
  text-align: right;
  margin-left: 5px;
  font-weight: bold;
  color: #ff0000;
  animation: growShrinksmall 2s ease-in-out 1; /*アニメーションで1回だけ実行 */
}
ul li .content .quantityover30 {
  display: inline-block; /* inline-blockで要素を独立させる */
  font-size: 14px;
  line-height: 1.2em;
  text-align: right;
  margin-left: 5px;
  font-weight: bold;
  color: #ff0000;
  animation: growShrinkbig 3s ease-in-out 1; /*アニメーションで1回だけ実行 */
}

@keyframes growShrinksmall {
  0% {
    transform: scale(1); /* 元のサイズ */
  }
  50% {
    transform: scale(1.3); /* 1.5倍に拡大 */
  }
  100% {
    transform: scale(1); /* 元のサイズに戻る */
  }
}

@keyframes growShrinkbig {
  0% {
    transform: scale(1); /* 元のサイズ */
  }
  50% {
    transform: scale(1.5); /* 1.5倍に拡大 */
  }
  100% {
    transform: scale(1); /* 元のサイズに戻る */
  }
}

ul li .content .quantityuntsmall {
  display: inline;
  font-size: 10px;
  line-height: 1.2em;
  text-align: right;
  margin-left: 4px;
  color: black;
}
ul li .goods,
ul li .card {
  position: absolute;
  top: -3px;
  left: -7px;
  /*display: inline-block;*/
  width: 27px;
  height: 27px;
  background-size: cover;
  background-color: #bbbbbb;
  border-radius: 100px;
  border: solid 2px #ffffff;
}
ul li .goods img,
ul li .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.message-container {
  /* max-height: 500px;  */
  overflow-y: auto;
  height: calc(100% - 110px);
}
</style>
