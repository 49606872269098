<script setup>
import { ref } from "vue";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useRouter, useRoute } from "vue-router";

const email = ref(null);
const emailRules = [
  (v) => !!v || "メールアドレスを入力してください",
  (v) => /.+@.+\..+/.test(v) || "メールアドレスを入力してください",
];
const password = ref(null);
const show = ref(false); // 値をrefとして初期化
const passwordRules = [(v) => !!v || "パスワードを入力してください"];
const text = ref(null);

const router = useRouter();
const toLink = (url) => {
  router.push(url);
};

const route = useRoute();
const redirectURL = ref(null);
redirectURL.value = ref(route.query.redirect);
console.log(route.path);
console.log(redirectURL.value.value);
const signIn = () => {
  console.log("サインイン開始");
  console.log(email.value);
  const auth = getAuth();
  signInWithEmailAndPassword(auth, email.value, password.value)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log(user);
      console.log(redirectURL.value);
      console.log("ログイン成功"); // eslint-disable-line no-console
      if (redirectURL.value.value == null) {
        toLink("/");
      } else {
        console.log(redirectURL.value.value);
        toLink(redirectURL.value.value);
      }
    })
    .catch((error) => {
      console.log(error); // eslint-disable-line no-console
      if (error.code === "auth/user-not-found") {
        text.value = "メールアドレスかパスワードに誤りがあります";
      } else if (error.code === "auth/invalid-email") {
        text.value = "メールアドレスの形式をご確認ください";
      } else {
        text.value = "メールアドレスかパスワードに誤りがあります";
      }
    });
};
const deletemessage = () => {
  text.value = null;
};
</script>
<template>
  <v-app>
    <div class="maincontainer">
      <h2 class="mt-12 title">
        SukiSukiらいぶすてーしょん配信者様画面にログイン
      </h2>
      <div class="mt-12">
        <v-form ref="form" lazy-validation class="form">
          <div @click="deletemessage">
            <div class="mailaddress">メールアドレス</div>
            <v-text-field
              class="ml-10 mr-10"
              v-model="email"
              :rules="emailRules"
              density="compact"
              required
            />
          </div>
          <div @click="deletemessage">
            <div class="mt-5 mailaddress">パスワードを入力</div>
            <v-text-field
              class="ml-10 mr-10"
              v-model="password"
              :rules="passwordRules"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              density="compact"
              required
              @click:append="show = !show"
            />
          </div>
          <div class="message">
            {{ text }}
          </div>
          <div class="btn-container">
            <!-- btn-containerを追加 -->
            <v-btn
              class="mt-5"
              height="35"
              width="100"
              rounded="pill"
              @click="signIn"
              color="rgba(89, 117, 156)"
            >
              <a class="login">ログイン</a>
            </v-btn>
          </div>
        </v-form>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
/* .form {
  width: 100%;
} */
.maincontainer {
  margin: 0 0 50px 0;
}
.title {
  font-size: 14px;
  text-align: center; /* タイトルを中央揃えにするためのスタイル */
}
.login {
  color: rgb(255 255 255);
  font-size: 12px;
}
.resetpassword {
  font-size: 10px;
  text-decoration: underline;
}
.resetpasswordcontainer {
  margin: 8px 0 0 0;
}
.signup {
  color: rgb(255 255 255 100%);
  font-size: 12px;
}
.mailaddress {
  font-size: 12px;
  text-align: left;
  margin-left: 40px;
}
.message {
  color: red;
  font-size: 10px;
  margin: 10px 0 0 0;
}

/*スマートフォン-----------------------------------------------------------------------------------*/
/*@media only screen and (max-width:768px){*/
@media only screen and (max-width: 1000px) {
  .login {
    color: rgb(255 255 255);
    font-size: 12px;
  }
}

.btn-container {
  display: flex;
  justify-content: center; /* ボタンを中央揃えにするためのスタイル */
}
</style>
