<script setup>
import { ref, watch, toRefs } from "vue";

const props = defineProps({
  rankingarray: Array,
  ranking_dialog: Boolean,
});
const emit = defineEmits(["cardclose"]);

const close = () => {
  emit("rankingclose");
};

const { rankingarray } = toRefs(props);
const showrankingarray = ref([]);

watch(
  rankingarray,
  (newValue) => {
    if (newValue) {
      showrankingarray.value = newValue.map((item) => {
        return { ...item, iconURL: resizeIconURL(item.iconURL) };
      });
    }
  },
  { immediate: true }
);

function resizeIconURL(iconURL) {
  return iconURL.replace(/iconphoto\.png$/, "iconphoto_400x400.png");
}
</script>

<template>
  <div class="modal_title">ランキング</div>
  <div class="inner">
    <div class="body">
      <ul>
        <template v-for="(item, index) in showrankingarray" :key="item">
          <li>
            <div class="icon_conteiner">
              <div class="icon"><img :src="item.iconURL" /></div>
              <div v-if="index == 0" class="rank">
                <img src="img/rank1.png" />
              </div>
              <div v-else-if="index == 1" class="rank">
                <img src="img/rank2.png" />
              </div>
              <div v-else-if="index == 2" class="rank">
                <img src="img/rank3.png" />
              </div>
            </div>
            <div class="name">{{ item.name }}</div>
          </li>
        </template>
      </ul>
    </div>
    <div class="btn_conteiner unit">
      <button class="return" @click="close">閉じる</button>
    </div>
  </div>
  <!-- 
    <div v-if="ranking_dialog" class="modal">
      <div class="background">
        <h2>ランキング</h2>
        <template v-for="item in showrankingarray" :key="item">
          <div>{{ item.name }}</div>
        </template>
      </div>
    </div>
    -->
</template>

<style scoped>
.modal {
  position: absolute;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
  border-radius: 15px 15px 0 0;
  box-shadow: 0px -10px 10px -5px rgba(0, 0, 0, 0.6);
  z-index: 100;
}
.modal .modal_title {
  width: 100%;
  padding: 12px 15px 10px 15px;
  text-align: center;
  font-size: 1rem;
  background: rgb(89, 117, 157);
  color: #ffffff;
  line-height: 1.2em;
  border-radius: 15px 15px 0 0;
  border-top: solid 1px rgb(255, 255, 255, 0.3);
}
.modal .inner {
  overflow: scroll;
  max-height: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.modal .body {
  padding: 0px 15px;
  font-size: 0.9rem;
  line-height: 1.5em;
}
.modal .body .unit {
  margin-bottom: 15px;
}
.modal .body p {
  margin-bottom: 10px;
}

.modal .btn_conteiner {
  text-align: center;
}
.modal button.normal,
.modal button.return {
  padding: 10px 15px;
  font-size: 0.9rem;
  border-radius: 100px;
  border: none;
  margin: 0 5px;
  background: rgb(89, 117, 157);
  color: #ffffff;
}
.modal button.normal:active {
  background: rgb(46, 68, 99);
}
.modal button.return {
  background: rgb(226, 226, 226);
  color: rgb(142 144 147);
}
.modal button.return:active {
  background: rgb(190, 190, 190);
  color: rgb(255 255 255);
}
.modal button.list {
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  font-size: 0.9rem;
  border: none;
  background: rgb(89, 117, 157);
  color: #ffffff;
  border-bottom: solid 1px rgb(132, 163, 206);
}
.modal button.list:active {
  background: rgb(46, 68, 99);
}
.modal .unit_outline {
  border-radius: 10px;
  border: solid 1px rgb(126, 126, 126);
  padding: 15px 10px 15px 10px;
  margin-bottom: 10px;
}

.textaline_center {
  text-align: center;
}
.textaline_left {
  text-align: left;
}
.textaline_right {
  text-align: right;
}
.margn_bottom_reset {
  margin-bottom: 0 !important;
}

ul {
}
ul li {
  display: flex;
  border-bottom: solid 1px #ccc;
  margin-bottom: 15px;
}
.icon_conteiner {
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.name {
  margin-top: 15px;
}
ul li .icon {
  border-radius: 100px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
ul li .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ul li .rank {
  position: absolute;
  display: inline-block;
  top: 27px;
  right: 0px;
  width: 15px;
  height: 20px;
}
ul li .rank img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
