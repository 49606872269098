<script setup>
import { ref } from "vue";
// const props = defineProps({
//   showmessage: Array,
// });

const emit = defineEmits(["commentinput"]);

const commenttext = ref(null);
const onKeypressEnter = async () => {
  console.log(commenttext.value);
  emit("commentinput", commenttext);
  await deletecommentcontainer();
};
//コメントを入力枠から削除する
const deletecommentcontainer = () => {
  commenttext.value = null;
};
</script>

<template>
  <input
    class="comment"
    type="text"
    placeholder="コメントする"
    v-model="commenttext"
    @keypress.enter="onKeypressEnter"
  />
</template>

<style scoped></style>
