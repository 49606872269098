<script setup>
import { ref, onMounted } from "vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useRoute } from "vue-router";
import VideoButton from "/src/components/atoms/VideoButton.vue";
import VideoButtonEnd from "/src/components/atoms/VideoButtonEnd.vue";
import db from "../main.js";
import {
  doc,
  onSnapshot,
  query,
  collection,
  orderBy,
  limit,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import AllMessage from "/src/components/molecules/AllMessage.vue";
import TrecaMessage from "/src/components/molecules/TrecaMessage.vue";
import GoodsMessage from "/src/components/molecules/GoodsMessage.vue";
import CommentUserMessage from "/src/components/molecules/CommentUserMessage.vue";
import VendorMessage from "/src/components/molecules/VendorMessage.vue";
import MainRanking from "/src/components/molecules/MainRanking.vue";
import MainCommnetInput from "/src/components/molecules/MainCommnetInput.vue";

const auth = getAuth();
const uid = ref();

const url = ref();
const srturl = ref();
const streamkey = ref();
const campaign = ref();
const allshowmessage = ref([]);
const trecashowmessage = ref([]);
const commentusershowmessage = ref([]);
const goodsshowmessage = ref([]);
const vendorshowmessage = ref([]);
const rankingarray = ref([]);
const vendor_ja = ref();
const vendor_en = ref();
const live_first = ref();
const liveFirstDate = ref();
const live_second = ref();
const liveSecondDate = ref();
const live_third = ref();
const liveThirdDate = ref();
const live_fourth = ref();
const liveFourthDate = ref();
const live_fifth = ref();
const liveFifthDate = ref();

const route = useRoute();
const queryParams = route.query;
console.log(queryParams);
// // クエリパラメータ 'livenumber' をチェックする
// if (queryParams.livenumber === "first") {
//   console.log("firstdayo----");
// } else {
//   console.log("firstじゃないよ");
// }
onMounted(async () => {
  await onAuthStateChanged(auth, (userdata) => {
    if (userdata) {
      uid.value = userdata.uid;
      console.log(uid);
      onSnapshot(doc(db, "Goods_vendorusers", uid.value), (doc) => {
        //日時取得用
        live_first.value = doc.data().live_first;
        liveFirstDate.value = new Date(
          live_first.value.seconds * 1000
        ).toLocaleString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
        live_second.value = doc.data().live_second;
        liveSecondDate.value = new Date(
          live_second.value.seconds * 1000
        ).toLocaleString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
        live_third.value = doc.data().live_third;
        liveThirdDate.value = new Date(
          live_third.value.seconds * 1000
        ).toLocaleString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
        live_fourth.value = doc.data().live_fourth;
        liveFourthDate.value = new Date(
          live_fourth.value.seconds * 1000
        ).toLocaleString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
        live_fifth.value = doc.data().live_fifth;
        liveFifthDate.value = new Date(
          live_fifth.value.seconds * 1000
        ).toLocaleString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });

        console.log("Current data: ", doc.data());
        console.log("Document data:", doc.data().srt);
        srturl.value = doc.data().srt;
        streamkey.value = doc.data().streamkey;
        campaign.value = doc.data().campaign;
        vendor_ja.value = doc.data().vendor_ja;
        vendor_en.value = doc.data().campaign;
        console.log(vendor_en.value);
        url.value =
          process.env.VUE_APP_CLOUDFLARE_SUBDOMAIN +
          doc.data().videouid +
          "/iframe?autoplay=true";
        console.log(url.value);
        getMessage(campaign.value);
        console.log("ランキング取得");
        const querypoint = query(
          collection(db, "Goods_ranking", campaign.value, "ranking"),
          limit(3),
          orderBy("points", "desc")
        );
        // , where("capital", "==", true));
        onSnapshot(querypoint, (querySnapshot) => {
          rankingarray.value = [];
          // const rankingarray = ref([]);
          if (querySnapshot.empty) {
            console.log("No matching documents.");
            const rankingarray = [];
            return rankingarray;
          }
          console.log(querySnapshot);
          // const querySnapshot = await onSnapshot(querypoint);
          querySnapshot.forEach((doc) => {
            console.log("ssssoooああああ");
            const setranking = doc.data();
            console.log(setranking);
            rankingarray.value.push(setranking);
            console.log(rankingarray.value);
          });
        });
      });
    } else {
      console.log("logout");
    }
  });
});

const functions = getFunctions();
functions.region = "asia-northeast1";

const VideoStart = async () => {
  console.log("xxxx");
  const getURL = httpsCallable(functions, "Goods_vendoradmin_geturl");
  getURL({ streamkey: streamkey.value, campaign: campaign.value })
    .then((res) => {
      console.log(res);
    })
    .catch((e) => {
      console.log(e);
    });
};

const getMessage = async (campaign) => {
  const q = query(
    collection(db, "Goods_automessages", campaign, "automessages"),
    orderBy("timestamp", "desc")
  );
  onSnapshot(q, (querySnapshot) => {
    const trecanameshot = [];
    const commentusernameshot = [];
    const goodsnameshot = [];
    const nameshot = [];
    querySnapshot.docChanges().forEach((change) => {
      //全台のメッセージ
      if (change.type === "added") {
        console.log("初期、追加: ", change.doc.data());

        const allsetmessage = change.doc.data();
        console.log(allsetmessage);
        allshowmessage.value.push(allsetmessage);
        console.log(allshowmessage.value);
      }
      if (change.type === "modified") {
        console.log("変更: ", change.doc.data());
      }
      if (change.type === "removed") {
        console.log("削除: ", change.doc.data());
      }
      if (change.doc.data().commenttype == "goods") {
        if (change.type === "added") {
          console.log("初期、追加: ", change.doc.data());

          const setmessage = change.doc.data();
          console.log(setmessage);
          goodsshowmessage.value.push(setmessage);
          console.log(goodsshowmessage.value);
        }
        if (change.type === "modified") {
          console.log("変更: ", change.doc.data());
        }
        if (change.type === "removed") {
          console.log("削除: ", change.doc.data());
        }
      }

      if (change.doc.data().commenttype == "commentuser") {
        if (change.type === "added") {
          console.log("初期、追加: ", change.doc.data());

          const commensetmessage = change.doc.data();
          console.log(commensetmessage);
          commentusershowmessage.value.push(commensetmessage);
          console.log(commentusershowmessage.value);
        }
        if (change.type === "modified") {
          console.log("変更: ", change.doc.data());
        }
        if (change.type === "removed") {
          console.log("削除: ", change.doc.data());
        }
      }

      if (change.doc.data().commenttype == "treca") {
        if (change.type === "added") {
          console.log("初期、追加: ", change.doc.data());

          const trecasetmessage = change.doc.data();
          console.log(trecasetmessage);
          trecashowmessage.value.push(trecasetmessage);
          console.log(trecashowmessage.value);
        }
        if (change.type === "modified") {
          console.log("変更: ", change.doc.data());
        }
        if (change.type === "removed") {
          console.log("削除: ", change.doc.data());
        }
      }

      if (change.doc.data().commenttype == "vendoruser") {
        if (change.type === "added") {
          console.log("初期、追加: ", change.doc.data());

          const vendorsetmessage = change.doc.data();
          console.log(vendorsetmessage);
          vendorshowmessage.value.push(vendorsetmessage);
          console.log(vendorshowmessage.value);
        }
        if (change.type === "modified") {
          console.log("変更: ", change.doc.data());
        }
        if (change.type === "removed") {
          console.log("削除: ", change.doc.data());
        }
      }
    });

    // console.log(nameshot);
    // showmessage.value = nameshot;
    // console.log(showmessage.value);
  });
};
//通常のコメントをデータベースへ書き込み
const commentinput = async (commenttext) => {
  console.log("function呼び出し");
  console.log(campaign.value);
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  //refを消す

  const vendornameNo = campaign.value;
  const normalmessageNo = commenttext.value;
  const vendornameJaNo = vendor_ja.value;
  const myCallableFunction = httpsCallable(functions, "Goods_vendorcomment");
  myCallableFunction({
    normalmessage: normalmessageNo,
    vendorname: vendornameNo,
    vendornameJa: vendornameJaNo,
  })
    .then((result) => {
      console.log(result);
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error.message);
    });
};

const Videostop = async () => {
  console.log("videostop");
  if (queryParams.livenumber === "first") {
    console.log("firstdayo----");
    const liveno = "第1回配信";
    const vendoren = vendor_en.value;
    console.log(vendoren);
    await searchAndUpdateLiveStatus(liveno, vendoren);
  } else if (queryParams.livenumber === "second") {
    console.log("seconddayo----");
  } else if (queryParams.livenumber === "third") {
    console.log("thirddayo----");
  } else if (queryParams.livenumber === "fourth") {
    console.log("fourthdayo----");
  } else if (queryParams.livenumber === "fifth") {
    console.log("fifthdayo----");
  } else {
    console.log("じゃないよ");
  }
};

const searchAndUpdateLiveStatus = async (liveno, vendoren) => {
  const q = query(
    collection(db, "Goods_liveshedule"),
    where("vendor_en", "==", vendoren),
    where("livenumber", "==", liveno)
  );

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    console.log(`Found document: ${doc.id} => ${JSON.stringify(doc.data())}`);
    updateLiveStatus(doc.id);
  });
};

const updateLiveStatus = async (docId) => {
  const docRef = doc(db, "Goods_liveshedule", docId);
  await updateDoc(docRef, { live_status: "end" });
};
</script>

<template>
  <div>ホーム</div>
  <div>
    <div v-if="queryParams.livenumber === 'first'">
      <p>配信者名</p>
      <p>{{ vendor_ja }}</p>
      <p>1回目配信</p>
      <p>{{ liveFirstDate }}</p>
    </div>
    <div v-if="queryParams.livenumber === 'second'">
      <p>2回目配信</p>
      <p>{{ liveSecondDate }}</p>
    </div>
    <div v-if="queryParams.livenumber === 'third'">
      <p>3回目配信</p>
      <p>{{ liveThirdDate }}</p>
    </div>
    <p>動画打ち上げ用URL</p>
    <p>{{ srturl }}</p>
    <p>ストリームキー</p>
    <p>{{ streamkey }}</p>
  </div>
  <div style="position: relative; padding-top: 56.25%">
    <iframe
      :src="url"
      style="
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      "
      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
      allowfullscreen="true"
    ></iframe>
  </div>

  <VideoButton @click="VideoStart" />
  <VideoButtonEnd @click="Videostop" />
  <AllMessage :allshowmessage="allshowmessage" />
  <TrecaMessage :trecashowmessage="trecashowmessage" />
  <GoodsMessage :goodsshowmessage="goodsshowmessage" />
  <CommentUserMessage :commentusershowmessage="commentusershowmessage" />
  <VendorMessage :vendorshowmessage="vendorshowmessage" />
  <MainRanking :rankingarray="rankingarray" />
  <MainCommnetInput @commentinput="commentinput" />
</template>
