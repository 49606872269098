<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const toLink = (url) => {
  router.push(url);
};
const logourl = ref(
  process.env.VUE_APP_GCS_URL +
    "/live_station_images_common/live_station_new_logo.png"
);
</script>

<template>
  <header class="app-bar">
    <h1 @click="toLink('/')">
      <!-- PCの表示 -->
      <img class="logo cursor logo-desktop" :src="logourl" alt="Logo" />
      <!-- スマホの表示 -->
      <!-- <img
        class="logo cursor logo-mobile"
        src="https://firebasestorage.googleapis.com/v0/b/sukisuki-dev.appspot.com/o/live_station_images_common%2Flive_station_logo.png?alt=media&token=3b1f747e-6e36-47b2-b262-b0ebf4294246"
        alt="Logo"
      /> -->
    </h1>
    <!-- PCの表示 -->
    <!-- <button
      class="account-btn account-btn-desktop"
      @click="toLink('/myaccount')"
    >
      <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M12,14.5C15.31,14.5 18,16.36 18,18.5V20H6V18.5C6,16.36 8.69,14.5 12,14.5M12,12C14.21,12 16,10.21 16,8C16,5.79 14.21,4 12,4C9.79,4 8,5.79 8,8C8,10.21 9.79,12 12,12Z"
        />
      </svg>
    </button> -->
    <!-- スマホの表示 -->
    <!-- <button
      class="account-btn account-btn-mobile"
      @click="toLink('/myaccount')"
    >
      <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M12,14.5C15.31,14.5 18,16.36 18,18.5V20H6V18.5C6,16.36 8.69,14.5 12,14.5M12,12C14.21,12 16,10.21 16,8C16,5.79 14.21,4 12,4C9.79,4 8,5.79 8,8C8,10.21 9.79,12 12,12Z"
        />
      </svg>
    </button> -->
  </header>
</template>

<style scoped>
.app-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid gray;
}

.logo {
  max-height: 80px;
}

.cursor {
  cursor: pointer;
}

.logo-desktop,
.account-btn-desktop {
  display: none;
}

@media screen and (min-width: 600px) {
  .logo-desktop,
  .account-btn-desktop {
    display: block;
  }

  .logo-mobile,
  .account-btn-mobile {
    display: none;
  }
}
</style>
