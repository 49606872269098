<template>
  <v-btn rounded class="white--text" color="red darken-4">
    <spam class="room">部屋に配信を開始する（ライブを流す）</spam>
  </v-btn>
</template>

<script></script>

<style>
.room {
  color: white;
}
</style>
