import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// import { getAuth } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyB5a8Oz_P9VMTJ1SmCCrmM_6X7Uqe7p0IA",
//   authDomain: "sukisuki-dev.firebaseapp.com",
//   projectId: "sukisuki-dev",
//   storageBucket: "sukisuki-dev.appspot.com",
//   messagingSenderId: "544343718694",
//   appId: "1:544343718694:web:5dcc4e91192f709e4e9fe7",
// };
//環境変数
const apiKey = process.env.VUE_APP_FIREBASE_APIKEY;
const authDomain = process.env.VUE_APP_FIREBASE_AUTHDOMAIN;
const projectId = process.env.VUE_APP_FIREBASE_PROJECTID;
const storageBucket = process.env.VUE_APP_FIREBASE_STORAGEBUCKET;
const messagingSenderId = process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID;
const appId = process.env.VUE_APP_FIREBASE_APPID;
const firebaseConfig = {
  apiKey: apiKey,
  authDomain: authDomain,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messagingSenderId,
  appId: appId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
// const auth = getAuth(app);
getFunctions(app);
export default db; //追加

loadFonts();

createApp(App).use(router).use(vuetify).mount("#app");
