<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import db from "../main.js";
import {
  doc,
  onSnapshot,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { useRouter } from "vue-router";

const auth = getAuth();
const uid = ref();

const srturl = ref();
const vendor_ja = ref();
const live_first = ref();
const liveFirstDate = ref();
const live_first_end = ref();
const liveFirstDateEnd = ref();
const live_second = ref();
const live_second_end = ref();
const liveSecondDate = ref();
const liveSecondDateEnd = ref();
const live_third = ref();
const live_third_end = ref();
const liveThirdDate = ref();
const liveThirdDateEnd = ref();
const live_fourth = ref();
const live_fourth_end = ref();
const liveFourthDate = ref();
const liveFourthDateEnd = ref();
const live_fifth = ref();
const live_fifth_end = ref();
const liveFifthDate = ref();
const liveFifthDateEnd = ref();
const streamkey = ref();
const campaign = ref();
const live_first_status = ref("配信前");
const live_second_status = ref("配信前");
const live_third_status = ref("配信前");
const live_fourth_status = ref("配信前");
const live_fifth_status = ref("配信前");
const liveSchedules = ref([]);
const iconurl = ref();
onMounted(() => {
  onAuthStateChanged(auth, (userdata) => {
    if (userdata) {
      uid.value = userdata.uid;
      geticonurl(uid.value);
      getCurrenteventid()
        .then(async (currenteventid) => {
          console.log(currenteventid);
          getvendor_ja(currenteventid);
          const docRef = doc(
            db,
            "Goods_products",
            currenteventid,
            "streaminfo",
            "streaminfo"
          );
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            srturl.value = docSnap.data().srt;
            streamkey.value = docSnap.data().streamkey;
            campaign.value = docSnap.data().campaign;

            const q = query(
              collection(db, "Goods_liveshedule"),
              where("event_id", "==", currenteventid)
            );

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              liveSchedules.value.push(doc.data());
              console.log(liveSchedules.value);
              updateLiveDatesFirst();
              updateLiveDatesSecond();
              updateLiveDatesThird();
              updateLiveDatesFourth();
              updateLiveDatesFifth();
            });
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.error("Error getting event id:", error);
        });
    } else {
      console.log("logout");
    }
  });
});

// 1回目の配信
const isBroadcastTimeForFirstLive = computed(() => {
  if (!live_first.value || !live_first_end.value) {
    return false;
  }

  const currentTime = Date.now();
  const startTime =
    new Date(live_first.value.seconds * 1000).getTime() - 23 * 60 * 60 * 1000; // 1時間前
  const endTime = new Date(live_first_end.value.seconds * 1000).getTime();

  return currentTime >= startTime && currentTime <= endTime;
});

// 2回目の配信
const isBroadcastTimeForSecondLive = computed(() => {
  if (!live_second.value || !live_second_end.value) {
    return false;
  }

  const currentTime = Date.now();
  const startTime =
    new Date(live_second.value.seconds * 1000).getTime() - 23 * 60 * 60 * 1000; // 23時間前
  const endTime = new Date(live_second_end.value.seconds * 1000).getTime();

  return currentTime >= startTime && currentTime <= endTime;
});

// 3回目の配信
const isBroadcastTimeForThirdLive = computed(() => {
  if (!live_third.value || !live_third_end.value) {
    return false;
  }

  const currentTime = Date.now();
  const startTime =
    new Date(live_third.value.seconds * 1000).getTime() - 23 * 60 * 60 * 1000; // 1時間前
  const endTime = new Date(live_third_end.value.seconds * 1000).getTime();

  return currentTime >= startTime && currentTime <= endTime;
});

// 4回目の配信
const isBroadcastTimeForFourthLive = computed(() => {
  if (!live_fourth.value || !live_fourth_end.value) {
    return false;
  }

  const currentTime = Date.now();
  const startTime =
    new Date(live_fourth.value.seconds * 1000).getTime() - 23 * 60 * 60 * 1000; // 1時間前
  const endTime = new Date(live_fourth_end.value.seconds * 1000).getTime();

  return currentTime >= startTime && currentTime <= endTime;
});

// 5回目の配信
const isBroadcastTimeForFifthLive = computed(() => {
  if (!live_fifth.value || !live_fifth_end.value) {
    return false;
  }

  const currentTime = Date.now();
  const startTime =
    new Date(live_fifth.value.seconds * 1000).getTime() - 23 * 60 * 60 * 1000; // 1時間前
  const endTime = new Date(live_fifth_end.value.seconds * 1000).getTime();

  return currentTime >= startTime && currentTime <= endTime;
});

const formatDate = (date) => {
  const month = date.getMonth() + 1; // JavaScriptの月は0から始まるので、+1しています。
  const day = date.getDate();
  const hour = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0"); // 0埋めをするために、padStartを使用しています。

  return `${month}月${day}日${hour}時${minutes}分`;
};

const router = useRouter();
const ToKanri = (times) => {
  console.log(times);
  router.push(`/broadcast?livenumber=${times}`);
};

const getCurrenteventid = async () => {
  try {
    const q = query(
      collection(db, "users", uid.value, "vendor_event"),
      orderBy("created_at", "desc"), // タイムスタンプで降順にソート
      limit(1) // 最新のもの1つだけ取得
    );

    const querySnapshot = await getDocs(q);

    // 最新のドキュメントを取得（orderByとlimitにより、1つのドキュメントのみ取得される）
    const latestEvent = querySnapshot.docs[0];

    if (latestEvent) {
      console.log("Latest event id: ", latestEvent.id);
      return latestEvent.id;
    } else {
      throw new Error("No event found");
    }
  } catch (error) {
    console.error("Error getting latest event id:", error);
    throw error;
  }
};

function updateLiveDatesFirst() {
  const liveSchedule = liveSchedules.value.find(
    (schedule) => schedule.livenumber === "第1回配信"
  );
  if (liveSchedule) {
    live_first.value = liveSchedule.livedate.start;
    liveFirstDate.value = formatDate(new Date(live_first.value.seconds * 1000));
    live_first_end.value = liveSchedule.livedate.end;
    liveFirstDateEnd.value = formatDate(
      new Date(live_first_end.value.seconds * 1000)
    );
  }
}

function updateLiveDatesSecond() {
  const liveSchedule = liveSchedules.value.find(
    (schedule) => schedule.livenumber === "第2回配信"
  );
  if (liveSchedule) {
    live_second.value = liveSchedule.livedate.start;
    liveSecondDate.value = formatDate(
      new Date(live_second.value.seconds * 1000)
    );
    live_second_end.value = liveSchedule.livedate.end;
    liveSecondDateEnd.value = formatDate(
      new Date(live_second_end.value.seconds * 1000)
    );
  }
}

function updateLiveDatesThird() {
  const liveSchedule = liveSchedules.value.find(
    (schedule) => schedule.livenumber === "第3回配信"
  );
  if (liveSchedule) {
    live_third.value = liveSchedule.livedate.start;
    liveThirdDate.value = formatDate(new Date(live_third.value.seconds * 1000));
    live_third_end.value = liveSchedule.livedate.end;
    liveThirdDateEnd.value = formatDate(
      new Date(live_third_end.value.seconds * 1000)
    );
  }
}

function updateLiveDatesFourth() {
  const liveSchedule = liveSchedules.value.find(
    (schedule) => schedule.livenumber === "第4回配信"
  );
  if (liveSchedule) {
    live_fourth.value = liveSchedule.livedate.start;
    liveFourthDate.value = formatDate(
      new Date(live_fourth.value.seconds * 1000)
    );
    live_fourth_end.value = liveSchedule.livedate.end;
    liveFourthDateEnd.value = formatDate(
      new Date(live_fourth_end.value.seconds * 1000)
    );
  }
}

function updateLiveDatesFifth() {
  const liveSchedule = liveSchedules.value.find(
    (schedule) => schedule.livenumber === "第5回配信"
  );
  if (liveSchedule) {
    live_fifth.value = liveSchedule.livedate.start;
    liveFifthDate.value = formatDate(new Date(live_fifth.value.seconds * 1000));
    live_fifth_end.value = liveSchedule.livedate.end;
    liveFifthDateEnd.value = formatDate(
      new Date(live_fifth_end.value.seconds * 1000)
    );
  }
}

const geticonurl = async (uid) => {
  console.log(uid);
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    iconurl.value = docSnap.data().iconURL;
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
};

const getvendor_ja = async (currenteventid) => {
  console.log(uid);
  const docRef = doc(db, "Goods_products", currenteventid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    vendor_ja.value = docSnap.data().vendor_ja;
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
};
</script>
<template>
  <div class="maincontainer">
    <h2 class="titlebar"><span class="titleword"> 配信者様管理画面</span></h2>
    <div>
      <div class="vendorcontainer" style="display: flex; align-items: center">
        <img
          :src="iconurl"
          alt="Vendor Icon"
          style="height: 50px; margin-right: 10px"
        />
        <p>配信者名 {{ vendor_ja }}</p>
      </div>

      <!-- Start of the table -->
      <table class="data-table">
        <thead>
          <tr>
            <th>配信回数</th>
            <th>配信日時</th>
            <th>配信ステータス</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1回目配信</td>
            <td>{{ liveFirstDate }}〜{{ liveFirstDateEnd }}</td>
            <td>
              <button
                :disabled="!isBroadcastTimeForFirstLive"
                @click="ToKanri('first')"
                :class="{
                  'disabled-button': !isBroadcastTimeForFirstLive,
                  'space-after-button': true,
                }"
              >
                配信する
              </button>

              {{ live_first_status }}
            </td>
          </tr>
          <tr>
            <td>2回目配信</td>
            <td>{{ liveSecondDate }}〜{{ liveSecondDateEnd }}</td>
            <td>
              <button
                :disabled="!isBroadcastTimeForSecondLive"
                @click="ToKanri('second')"
                :class="{
                  'disabled-button': !isBroadcastTimeForSecondLive,
                  'space-after-button': true,
                }"
              >
                配信する
              </button>
              {{ live_second_status }}
            </td>
          </tr>
          <tr>
            <td>3回目配信</td>
            <td>{{ liveThirdDate }}〜{{ liveThirdDateEnd }}</td>
            <td>
              <button
                :disabled="!isBroadcastTimeForThirdLive"
                @click="ToKanri('third')"
                :class="{
                  'disabled-button': !isBroadcastTimeForThirdLive,
                  'space-after-button': true,
                }"
              >
                配信する
              </button>
              {{ live_third_status }}
            </td>
          </tr>
          <tr>
            <td>4回目配信</td>
            <td>{{ liveFourthDate }}〜{{ liveFourthDateEnd }}</td>
            <td>
              <button
                :disabled="!isBroadcastTimeForFourthLive"
                @click="ToKanri('fourth')"
                :class="{
                  'disabled-button': !isBroadcastTimeForFourthLive,
                  'space-after-button': true,
                }"
              >
                配信する
              </button>
              {{ live_fourth_status }}
            </td>
          </tr>
          <tr>
            <td>5回目配信</td>
            <td>{{ liveFifthDate }}〜{{ liveFifthDateEnd }}</td>
            <td>
              <button
                :disabled="!isBroadcastTimeForFifthLive"
                @click="ToKanri('fifth')"
                :class="{
                  'disabled-button': !isBroadcastTimeForFifthLive,
                  'space-after-button': true,
                }"
              >
                配信する
              </button>
              {{ live_fifth_status }}
            </td>
          </tr>
        </tbody>
      </table>
      <!-- End of the table -->
    </div>
  </div>
</template>

<style scoped>
.maincontainer {
  background-color: rgb(59, 59, 59);
  min-height: 100vh; /* 追加する部分 */
  margin: 0; /* マージンを0にする */
  padding: 0; /* パディングを0にする */
}

.titlebar {
  background-color: rgb(59, 59, 59);
  text-align: center;
}

.titleword {
  color: white;
  margin: 0;
}

.vendorcontainer {
  background-color: black;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.vendorcontainer img {
  border-radius: 50%;
}

.data-table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
  color: white; /* テーブル内の文字色を白に設定 */
}

.data-table th,
.data-table td {
  border: 1px solid white;
  padding: 8px;
  text-align: center; /* コンテンツを水平に中央揃え */
  vertical-align: middle; /* コンテンツを垂直に中央揃え */
}
.data-table button.disabled-button {
  background-color: rgb(216, 216, 216);
}

.data-table th {
  background-color: rgb(59, 59, 59);
  color: white;
}

.data-table tbody tr {
  height: 60px;
  background-color: rgb(59, 59, 59); /* 2行目以降のテーブル行の背景色 */
}

.data-table button {
  background-color: rgb(237, 121, 149);
  color: white;
  padding: 5px 15px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.space-after-button {
  margin-right: 40px; /* こちらの20pxはお好みの値に調整してください */
}
</style>
