<script setup>
import { ref, watch } from "vue";
import TheHeader from "/src/views/TheHeader.vue";
// import TheFooter from "/src/views/TheFooter.vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useRoute } from "vue-router";
const user = ref(null);
const auth = getAuth();
const useruid = ref(null);
onAuthStateChanged(auth, (userdata) => {
  if (userdata) {
    console.log(userdata);
    user.value = userdata;
    console.log(user.value.uid);
    console.log(typeof user.value);
    useruid.value = user.value.uid;
    console.log(useruid.value);
    // ...
  } else {
    console.log("logout");
    // User is signed out
    // ...
  }
});
//配信ページの場合ヘッダーとフッターを非表示にする
const defaultpage = ref(true);
const route = useRoute();
console.log(route.path);

watch(route, () => {
  console.log(route.path);
  if (route.path == "/broadcast") {
    defaultpage.value = false;
    console.log(defaultpage.value);
  } else {
    defaultpage.value = true;
  }
});
</script>
<template>
  <template v-if="defaultpage">
    <v-app>
      <TheHeader />
      <v-main class="main">
        <router-view :user="user" />
      </v-main>
      <!-- <TheFooter /> -->
    </v-app>
  </template>
  <template v-else>
    <router-view :user="user" />
  </template>
</template>

<style>
html {
  overflow-y: hidden !important;
}
</style>
