import { createRouter, createWebHistory } from "vue-router";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import TheHome from "../views/TheHome.vue";
import TheLogin from "../views/TheLogin.vue";
import TheKanri from "../views/TheKanri.vue";
import Broadcast from "../views/TheBroadcast.vue";

function checkAuthBeforeEnter(to, from, next) {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // eslint-disable-next-line no-param-reassign
      to.meta.user = user;
      console.log(user);
      next();
    } else {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
  });
}

const routes = [
  {
    path: "/",
    name: "home",
    component: TheHome,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/login",
    name: "login",
    component: TheLogin,
  },
  {
    path: "/kanri",
    name: "kanri",
    component: TheKanri,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
  {
    path: "/broadcast",
    name: "broadcast",
    component: Broadcast,
    beforeEnter: checkAuthBeforeEnter,
    props: (route) => ({ user: route.meta.user }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
